<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <!-- Navigation-->
  <div>
    <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
      <div class="container">
        <router-link :to="{name: 'home'}" class="navbar-brand js-scroll-trigger" href="#page-top">应用商店发行预审</router-link>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto my-2 my-lg-0">
            <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#services">文档</a></li>
            <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">定价</a></li>
            <template v-if="token">
              <li class="nav-item"><a class="nav-link js-scroll-trigger">
                <b-nav-item-dropdown :text="email" toggle-class="nav-link-custom">
                  <b-dropdown-item @click="goProfile">个人中心</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="signOut">退出登录</b-dropdown-item>
                </b-nav-item-dropdown>
              </a></li>
            </template>
            <template v-else>
              <b-link>
                <li class="nav-item"><a class="nav-link js-scroll-trigger" @click="goUpload">开始使用</a></li>
              </b-link>
            </template>
            <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#contact">联系我们</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

</template>

<script>
  import {BDropdown, BDropdownItem, BDropdownDivider, BNavItemDropdown} from 'bootstrap-vue/src/index'
  export default {
    name: "HomeHeader",
    components:{
      BDropdown, BDropdownItem, BDropdownDivider,BNavItemDropdown
    },
    data(){
      return{
        projectId: 0,
        token: this.$store.getters.token,
        email: this.$store.getters.email,
        showed: false,
      }
    },
    methods:{
      goUpload(){
        this.$router.push({name: 'upload'})
      },
      goProfile(){
        this.$router.push({name: 'profile'})
      },
      signOut(){
        this.$store.dispatch('logout').then(() => {
          this.$router.replace({name: 'refresh', query: {t: Date.now()}});
        })
      }
    }
  }
</script>

<style scoped>

  .navbar-light .navbar-nav /deep/ .nav-link{
    color: white;
  }

  /deep/ .dropdown-menu {
    opacity: 0.7;
  }
  /deep/ .dropdown-item {
  }
  /deep/ .dropdown-item:hover{
    color: #f4623a;
  }

</style>
