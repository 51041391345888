<template>
  <!-- Footer-->
  <footer class="bg-light py-5">
    <div class="container"><div class="small text-center text-muted">Copyright © 2023 - AppStoreFlow应用预审</div></div>
  </footer>

</template>

<script>
  export default {
    name: "MyFooter"
  }
</script>

<style scoped>

</style>
