<template>
  <div class="global-layout">
    <HomeHeader v-if="currRouterName==='home'"></HomeHeader>
    <PubHeader v-else></PubHeader>
    <div style="flex: 1;">
      <router-view></router-view>
    </div>
    <MyFooter></MyFooter>
  </div>
</template>

<script>

  import HomeHeader from '../header/HomeHeader'
  import PubHeader from '../header/PubHeader'
  import MyFooter from '../common/MyFooter'
  export default {
    name: "Layout",
    components:{
      HomeHeader,
      MyFooter,
      PubHeader
    },
    data(){
      return {
        currRouterName:'',
      }
    },
    mounted() {
      this.currRouterName = this.$route.name;
      console.log(this.currRouterName)

    }
  }
</script>

<style scoped>
  .global-layout{
    display: flex;
    display: -webkit-flex; /* Safari */
    min-height: 100vh;
    flex-direction: column;
  }
</style>
