<template>
  <!-- Navigation-->
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top py-3">
      <div class="container">
        <router-link :to="{name: 'home'}" class="navbar-brand js-scroll-trigger">应用商店发行预审</router-link>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto my-2 my-lg-0">
            <li class="nav-item"><a class="nav-link js-scroll-trigger">文档</a></li>
            <li class="nav-item"><a class="nav-link js-scroll-trigger">定价</a></li>
            <b-link>
              <li class="nav-item"><a class="nav-link js-scroll-trigger" @click="goUpload">开始使用</a></li>
            </b-link>
            <li class="nav-item"><a class="nav-link js-scroll-trigger">联系我们</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

</template>

<script>
  import {BDropdown, BDropdownItem, BDropdownDivider, BNavItemDropdown} from 'bootstrap-vue/src/index'
  export default {
    name: "PubHeader",
    components:{
      BDropdown, BDropdownItem, BDropdownDivider,BNavItemDropdown
    },
    data(){
      return{
        projectId: 0,
        token: this.$store.getters.token,
        email: this.$store.getters.email,
        showed: false,
      }
    },
    methods:{
      goUpload(){
        this.$router.push({name: 'upload'})
      },
      goProfile(){
        this.$router.push({name: 'profile'})
      },
      signOut(){
        this.$store.dispatch('logout').then(() => {
          this.$router.push({name: 'home'});
        })
      }
    }
  }
</script>

<style scoped>

</style>
